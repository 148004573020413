import { transparentize, darken as polishedDarken } from 'polished';

import {
  BORDERS,
  SHADOWS,
  SIZES as TYPE_SIZES,
  FONT_SIZES as TYPE_FONT_SIZES,
} from './types';

const color = {
  // http://chir.ag/projects/name-that-color/
  transparent: 'rgba(0,0,0,0)',
  black: '#000000',
  white: '#FFFFFF',
  springWood: '#F9F6F1',
  gray: '#838383',
  codGray: '#161616',
  mineShaft: '#2D2C2C',
  mulledWine: '#504363',
  bleachedCeda: '#301E35',
  beige: '#ECE1D4',
  shipGray: '#323133',
  cornFlower: '#608FE9',
  porsche: '#E9AD60',
  asparagus: '#779557',
  red: '#FF0000',
  error: '#CE4646',
};

const fontSize: TYPE_FONT_SIZES = {
  small: {
    size: '12px',
    lineHeight: '1.3',
    fontWeight: '300',
  },
  regular: {
    size: '14px',
    lineHeight: '1.4',
    fontWeight: '300',
  },
  large: {
    size: '16px',
    lineHeight: '1.5',
    fontWeight: '300',
  },
  h1: {
    size: '46px',
    lineHeight: '1.3',
    fontWeight: '700',
  },
  h2: {
    size: '30px',
    lineHeight: '1.3',
    fontWeight: '700',
  },
  h3: {
    size: '20px',
    lineHeight: '1.4',
    fontWeight: '700',
  },
  h4: {
    size: '16px',
    lineHeight: '1.25',
    fontWeight: '700',
  },
  h5: {
    size: '14px',
    lineHeight: '1.4',
    fontWeight: '700',
  },
};

// breakpoint reference https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/
const breakpoints = {
  mobile:
    'media only screen and (min-device-width : 30px) and (max-device-width : 50px)',
  desktop: 'media only screen and (min-width: 350px)',
};

const padding: TYPE_SIZES = {
  none: '0',
  xs: '3px',
  sm: '6px',
  md: '10px',
  lg: '20px',
  xl: '40px',
  xxl: '80px',
};

const margin: TYPE_SIZES = {
  none: '0',
  xs: '3px',
  sm: '5px',
  md: '10px',
  lg: '20px',
  xl: '40px',
  xxl: '80px',
};

const borderRadius: TYPE_SIZES = {
  none: '0',
  xs: '3px',
  sm: '5px',
  md: '12px',
  lg: '20px',
  xl: '40px',
  xxl: '100px',
};

const border: BORDERS = {
  primary: '1px solid #EAE9EB',
  secondary: '1px solid #ECE1D4',
};

const shadow: SHADOWS = {
  light: '0px 0px 10px rgba(140, 138, 138, 0.1);',
};

export const zIndex = {
  cBOverlay: 100,
  cB: 101,
};

export type COLORS = keyof typeof color | [keyof typeof color, number];
export type SIZES = keyof TYPE_SIZES | number;
export type FONT_SIZES = keyof TYPE_FONT_SIZES | number;
export type BORDER_RADIUS_SIZES = keyof TYPE_SIZES;

export const parseColor = (
  value: keyof typeof color | [keyof typeof color, number],
) => {
  let parsedColor = '';
  if (typeof value === 'string') {
    parsedColor = color[value];
  }
  if (Array.isArray(value)) {
    const [c, opacity] = value;
    parsedColor = transparentize(1 - opacity, color[c]);
  }

  return parsedColor;
};

export function darken(color: COLORS, opacity: number) {
  return polishedDarken(opacity, parseColor(color));
}

export const parseMargin = (value: SIZES) => {
  return typeof value === 'number' ? `${value}px` : margin[value];
};

export const parsePadding = (value: SIZES) => {
  return typeof value === 'number' ? `${value}px` : padding[value];
};

export default {
  breakpoints,
  color,
  shadow,
  fontSize,
  padding,
  margin,
  borderRadius,
  border,
  zIndex,
};
