import styled, { css, CSSProperties } from 'styled-components';

import theme, {
  parseColor,
  parseMargin,
  COLORS,
  FONT_SIZES,
  SIZES,
} from '@theme';

const { fontSize } = theme;

interface Props {
  opacity?: number;
  block?: boolean;
  align?: CSSProperties['textAlign'];
  weight?: CSSProperties['fontWeight'] | number;
  display?: CSSProperties['display'];
  size?: FONT_SIZES;
  color?: COLORS;
  marginB?: SIZES;
  marginT?: SIZES;
  marginR?: SIZES;
  marginL?: SIZES;
  lineHeight?: number;
  truncate?: boolean;
  maxLines?: number;
  maxWidth?: string;
}

export const Text = styled.span<Props>`
  ${({
    display = 'inline-block',
    block,
    size = 'regular',
    marginB = 0,
    marginT = 0,
    marginL = 0,
    marginR = 0,
    opacity = 1,
    weight,
    align,
    color = 'black',
    lineHeight,
    onClick,
    truncate,
    maxLines,
    maxWidth,
  }) => {
    const defaultLineHeight =
      typeof size === 'number' ? 'auto' : fontSize[size].lineHeight;
    const defaultFontWeight =
      weight ?? typeof size === 'number'
        ? weight ?? '300'
        : fontSize[size].fontWeight;

    return css`
      ${onClick ? 'cursor: pointer;' : ''};
      display: ${display};
      opacity: ${opacity};
      margin-bottom: ${parseMargin(marginB)};
      margin-top: ${parseMargin(marginT)};
      margin-left: ${parseMargin(marginL)};
      margin-right: ${parseMargin(marginR)};
      font-size: ${typeof size === 'number'
        ? `${size}px`
        : fontSize[size].size};
      line-height: ${lineHeight ? `${lineHeight}px` : defaultLineHeight};
      color: ${parseColor(color)};
      font-weight: ${defaultFontWeight};
      ${align &&
      css`
        text-align: ${align};
        display: block;
        width: 100%;
      `}
      ${block &&
      css`
        display: block;
        width: 100%;
      `}
        ${truncate &&
      css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
      ${maxLines &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${maxLines};
        -webkit-box-orient: vertical;
      `}
          ${maxWidth &&
      css`
        max-width: ${maxWidth};
      `}
    `;
  }}
`;
